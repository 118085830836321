<template>
	<div>
		<headertop/>
		<headers/>
		<div class="pwidth goodslist cart" id="cart" v-cloak>
		
			<div ref="element">
				<div class="sort flex" v-if="cartpublic">
					
					<p class="c-3 f-18 i-b tr l-h-50 bold flex" v-if="$store.state.app.Operational"><span class="shu m-t-10 m-r-5"></span> {{$store.state.app.Operational.goodlist.Orderapplication}}</p>
					<div class="selectactive tc">
						<div>
							<p class="c-6 cursor" @click="scoill('all')">全部商品 <span
									class="c-b14ei">({{cartpublic.totalQty}})</span> </p>
							<p class="m-l-15">|</p>
							<p class="c-6 cursor m-l-15" @click="scoill('wux')">无效商品 <span
									class=" c-ffa">({{wxgoods.length}})
								</span></p>
						</div>
					</div>
				</div>
		
				<!-- 商品列表 -->
				<ul class="searchList" ref="yxgoodsscoll">
					<li class="bg-f m-b-10" v-for="(itemcart,indexcart) in cartlistdata" :label="itemcart" :key="indexcart">
						<!-- 促销标题 -->
						<div class="flex start m-t-20 border-b p-b-20 p-t-20 p-l-10" v-if="itemcart.pricetype">
							<div class="flex start">
								<p class="i-b  f-12 m-r-10 c-f bg-f00 rad-4 box p-0-5 h-20 l-h-19 tc m-r-10">
									{{pricetype[itemcart.pricetype]}}</p>
								<span class="c-ff3 m-r-10">{{itemcart.promoteTitle}}</span>
							</div>
						</div>
						<div class="m-b-10 relative" :class="{'border-t p-t-10':index!=0}" v-for="(item,index) in itemcart.cartItemList" :label="item" :key="index">
							<div class="w-50 bg-ff0 h-170 absolute t-0  m-a l-0 tc z-i-10 flex" @click.stop="ckeckitemfn(item)" v-if="item.isvisible!=0">
								<i class="iconfont m-r-10 c-b14e  h-20 m-a f-16" :class="item.itemSelected?'b2b-a-fuxuankuangxuanzhong':'b2b-a-fuxuankuangweixuanzhong'"  ></i>
							</div>
							<div class="relative list w98 p-l-35 box">
								<div class="p-20 h-165 w-165 relative" @click="gotodetail(item.goodsid)">
									<img v-if="item.imageurl" :src="item.imageurl" onerror="javascript:this.src='../../assets/img/mr.png';" style="width: 126px;height: 126px;">
									<img v-if="!item.imageurl" src="../../assets/img/mr.png" alt="" style="width: 126px;height: 126px;">
									<img v-if="item.specialoffer==1" class="absolute t-0  l-0 " style="width: 35px;height: 40px;" src="../../assets/img/teja.png">
								</div>
			
								<div class="detail " style="width: 980px;">
									<div class=" title">
										<h3 @click="gotodetail(item.goodsid)" class="cursor">
											<span> {{item.goodsname}}</span>
											<i v-if="item.isprescription" class="f-10 h-20 l-h-16 i-b">{{item.isprescription?
												'RX':'' }}</i>
											<i v-if="item.isephedrine">{{item.isephedrine? '麻黄碱':''}}</i>
										</h3>
										<div class="flex c-6">
											<div class="m-r-10 cursor" v-if="item.batchno=='defaultbno'&&item.promoteInfoVOList.length"
												@click="chageerpcustnobtn(item)"><i class="b2b-cuxiao iconfont m-r-5"></i>换促销</div>
											<div class="m-r-10 cursor c-6" @click="delcart(item)">
												<i class="el-icon-delete p-20-0 m-r-5"></i><span>删除</span>
											</div>
										</div>
									</div>
			
			
									<div class="describe m-b-10">
										<ul class="bg-f9 p-5-10">
											<li>{{goodsview[$store.state.app.goodsvivewtype].tymc}}：{{item[goodsview[$store.state.app.goodsvivewtype].tymcval]}}</li>
											<li>{{goodsview[$store.state.app.goodsvivewtype].bzgg}}：{{item[goodsview[$store.state.app.goodsvivewtype].bzggval]}}</li>
											<li>{{goodsview[$store.state.app.goodsvivewtype].xh}}：{{item[goodsview[$store.state.app.goodsvivewtype].xhval]}}</li>
											<li>{{goodsview[$store.state.app.goodsvivewtype].zczh}}：{{item[goodsview[$store.state.app.goodsvivewtype].zczhval]}}</li>
											<li> 产品单位：{{item.unit}}</li>
											<li class="ell">{{goodsview[$store.state.app.goodsvivewtype].gg}}：{{item[goodsview[$store.state.app.goodsvivewtype].ggval]}}</li>
											<li> 生产厂家：{{item.manufacturersname}}</li>
											<li v-if="item.producedate"> 生产日期：{{item.producedate}}</li>
											<li v-if="showStock==1">库存：{{item.totalstock}}</li>
											<li v-if="$store.state.app.showprice!=1">建议零售价：<span class="bold">￥{{item.retailprice}}</span> </li>
											<li v-if="item.validdate"> 有效日期：{{item.validdate}}</li>
										</ul>
									</div>
									<div class="flex  l-h-30 m-b-10">
										<p v-if="item.batchno=='defaultbno'" class="w-150"><span class="i-b b-a-color tc --color h-20 p-0-5 l-h-16 f-12">默认分配</span> </p>
										<div v-if="item.batchno!='defaultbno'" class="w-150"><p>批号：{{item.batchno}}</p></div>
										<p class="l-h-35 m-r-50 w-200">业务组：{{item.batchnosalegroupname}}</p>
										<div v-if="$store.state.app.showprice!=1">
											<div class="w-300 " v-if="!item.seckillItem"> 批发价：<span class="c-ff3">￥</span><span class="c-ff3 bold f-20">{{item.price}}</span> </div>
											<div class="w-300 " v-else> 秒杀价：<span class="c-ff3 bold">￥{{item.seckill}}</span></div>
										</div>
										
										<div class=" flex start m-r-20 w-300 ">
											<span class="w-70">{{$store.state.app.Operational.goodlist.bayqtu}}：</span>
											<div class="el-input el-input--small" style="width: 130px;">
												 <el-input-number size="mini" :step="item.fltp?item.fltp:1"  v-model="item.qty" @change="changeinput(item)" :min="item.fltp?item.fltp:1" :max="item.totalstock" label="描述文字"></el-input-number>
											</div>
											<p class="c-9 m-l-10 f-12" v-if="item.littleqty!=0&&item.littleqty!=1">
												注：最小购买添加数量单位为{{item.littleqty}}</p>
										</div>
									</div>
			
									<!-- 赠品 -->
									<div class="relative border-t" v-if="item.gift&&index==0">
										<div class="absolute l--145 t-0">
											<img v-if="item.gift.imageurl" :src="item.gift.imageurl"
												onerror="javascript:this.src='../../assets/img/mr.png';"
												style="width: 126px;height: 126px;">
											<img v-if="!item.gift.imageurl" style="width: 126px;height: 126px;"
												src="../../assets/img/mr.png" alt="">
										</div>
										<div class=" title">
											<h3>
												<span><span class="c-ff8">【赠品】</span> {{item.gift.goodsname}}</span>
												<i class="f-10" v-if="item.gift.isprescription">{{item.gift.isprescription?
													'RX':'' }}</i>
												<i v-if="item.gift.isephedrine">{{item.gift.isephedrine? '麻黄碱':''}}</i>
											</h3>
			
										</div>
										<div class="describe m-b-10">
											<ul class="bg-f9 p-5-10">
												<li>{{goodsview[$store.state.app.goodsvivewtype].tymc}}：{{item[goodsview[$store.state.app.goodsvivewtype].tymcval]}}</li>
												<li class="ell">{{goodsview[$store.state.app.goodsvivewtype].gg}}：{{item[goodsview[$store.state.app.goodsvivewtype].ggval]}}</li>
												<li>{{goodsview[$store.state.app.goodsvivewtype].xh}}：{{item[goodsview[$store.state.app.goodsvivewtype].ggval]}}</li>
												<li> {{goodsview[$store.state.app.goodsvivewtype].zczh}}：{{item[goodsview[$store.state.app.goodsvivewtype].zczhval]}}</li>
												<li> 产品单位：{{item.gift.unit}}</li>
												<li> {{goodsview[$store.state.app.goodsvivewtype].bzgg}}：{{item[goodsview[$store.state.app.goodsvivewtype].bzggval]}}</li>
												<li> 生产厂家：{{item.gift.manufacturersname}}</li>
											</ul>
										</div>
										<div class="flex start">
			
											<!-- <div class="w-300 "> 批发价：<span class="c-ff8 bold">￥{{item.gift.price}}</span> </div> -->
											<div>赠送数量：{{item.giftCount}}
												<span class=" m-l-10" v-if="item.giftCount>item.gift.stockqty">
													<span class="m-l-10 m-r-10"> | </span> 实赠数量：
													{{item.giftCount>item.gift.stockqty?item.gift.stockqty:item.giftCount}}
													<span class="c-ff3 m-l-10">库存数量{{item.gift.stockqty}}，赠品库存不足</span> </span>
											</div>
										</div>
									</div>
								</div>
							</div>
		
						</div>
		
					</li>
				</ul>
		
				<!-- 失效商品 -->
				<div class="losegoods m-b-30" v-if="wxgoods.length!=0">
					<div class="l-h-50 p-0-20 m-t-20 bg-f flex blod">已失效商品
						<!--  -->
					</div>
		<!-- cartlistdata -->
					<div class="m-t-10">
						<div class="pwidth list p-t-10" v-for="(item,index) in wxgoods" :label="item" :key="index" >
							<div class="goodsimg">
								<img v-if="item.imageurl" :src="item.imageurl"
									onerror="javascript:this.src='../../assets/img/mr.png';">
								<img v-if="!item.imageurl" src="../../assets/img/mr.png" alt="">
							</div>
							<div class="detail">
								<div class="border-b title p-r-20">
									<h3>
										<span class="f-16 m-r-10"> {{item.goodsname}}</span>
										<i v-if="item.isprescription" class="f-12 l-h-16 h-20 i-b">{{item.isprescription?
											'RX':'' }}</i>
										<i v-if="item.isephedrine">{{item.isephedrine? '麻黄碱':''}}</i>
										<!-- <span class="c-ff3 f-10">( {{item.unenablereason}} )</span> -->
									</h3>

									<div class="m-r-10 cursor c-6" @click="delcart(item)">
										<i class="el-icon-delete p-20-0 m-r-5"></i><span>删除</span>
									</div>
									
								</div>
		
								<div class="describe relative">
									<ul>
										<li> 通用名称：{{item.goodsname2}}</li>
										<li> 产品规格：{{item.spec}}</li>
										<li> 品牌：{{item.brandname}}</li>
										<li> 批准文号：{{item.approvalno}}</li>
										<li> 产品单位：{{item.unit}}</li>
										<li> 合同价：--/--</li>
										<li> 生产厂家：{{item.manufacturersname}}</li>
										<li> 剂型：{{item.dosage}}</li>
										<li> 批号：{{item.batchno}}</li>
										<li> 生产日期：{{item.producedate}}</li>
										<li> 有效日期：{{item.validdate}}</li>
										<!-- <li> 库&nbsp;&nbsp;&nbsp;存：{{item.stockqty}}</li> -->
									</ul>
									<div class="p-r-35 absolute r-15 t-10">
										<img src="../../assets/img/lose.png" alt="">
									</div>
									<div class="addnumber " style="width: 200px;"
										v-show="item.unenablereason=='采购数量超套餐数量'">
										<div class="reduceplus tr">
											<span>{{$store.state.app.Operational.goodlist.bayqtu}}：</span>
											
											<input type="text" v-model="item.qty" @change="changeinput(item)"
												@blur="updateCart(item)" oninput="if(value.toString().indexOf('-')){
												value = parseInt(value.slice(0, 4))||1
												}else if(value<0){ value=parseInt(value.slice(1, 4))}">
										
										</div>
									</div>
		
								</div>
		
							</div>
		
						</div>
					</div>
				</div>
		
				<div v-if="!cartlistdata.length&&!wxgoods.length">
					<div class="pwidth bg-f orderdetal m-b-40" v-if="$store.state.app.Operational">
						<div class="tc m-b-75 h-150">
							<img v-if="$store.state.app.Operational.goodlist.carticon" src="../../assets/img/sc-1.png" alt="">
							<img v-else src="../../assets/img/kgwc.png" />
							<div class="f-26 m-t-20">{{$store.state.app.Operational.goodlist.carttitle}}</div>
						</div>
						<div class="lookorder tc">
							<div class="--b-a-1 --color w-120 m-r-40 rad-4" @click="succsee(0)">{{$store.state.app.Operational.goodlist.lookorder}}</div>
							<div class="--bg w-120 c-f rad-4" @click="succsee(1)">{{$store.state.app.Operational.goodlist.continuebrowse}}</div>
						</div>
					</div>
				</div>
			</div>
			
			<!-- 结算 -->
			<div class="pwidth bg-f Settlement m-t-20" :class="isfixed?'fixed':'m-b-30'" v-if="cartlistdata.length!=0">
				<div class="left">
					<!-- <i class="radio-m check m-t-17" :class="{'radio':allactive}" 
						v-show="yxgoods.length"></i> -->
					<i class="iconfont m-r-10 c-b14e f-16" :class="allactive?'b2b-a-fuxuankuangxuanzhong':'b2b-a-fuxuankuangweixuanzhong'" @click="allcheck" v-show="yxgoods.length"></i>
					<span class="m-r-10" v-show="yxgoods.length">全选</span>
					<span v-show="yxgoods.length">|</span>
					<span v-show="yxgoods.length" @click="delcart('all')"
						class="c-ff3 m-0-10 cursor m-r-50 m-l-10">删除</span>
				</div>
				<div class="tc as">
					<p class="num m-r-30">已选商品 
						<span class="c-ff8 f-20 m-0-10">{{cartpublic.totalSelQty||0}}</span> 件
						<span v-if="$store.state.app.showprice!=1">，商品金额： <span class="c-ff3 "> ￥<span class="f-20 bold">{{cartpublic.goodsTotalAmount}}</span></span></span>
					</p>
					<p class="cursor count --bg" @click="account" v-if="$store.state.app.Operational">{{ $store.state.app.Operational.goodlist.Confirmorder}}</p>
				</div>
			</div>
		
			<!-- 跟换促销 -->
			<div class="payloaging elselectcustno">
				<el-dialog title="请选促销活动" :visible.sync="chageerpcustno" :show-close="false" :close-on-click-modal="false">
					<div class="relative">
						<el-select v-model="promotesalesid" placeholder="请选择促销">
							<el-option v-for="(item,index) in promotesalesList" :key="index" :label="item.salesname"
								:value="item.promotesalesid"> </el-option>
						</el-select>
						<!-- <img class="w-26 h-26 absolute l-10 t-8" src="../../assets/img/cx.png" > -->
					</div>
		
					<div class="flex f-14 s-c tc absolute b-50  w100 l-0 p-l-120 p-r-120">
						<p class="w-100 rad-2 h-34 l-h-32 c-b14e cursor border-a-00b-2 m-r-20"
							@click="selectPromotesales(0)">取消</p>
						<p class="w-100 rad-2 h-34 l-h-34 bg-00B c-f cursor" @click="selectPromotesales(1)">确定</p>
					</div>
				</el-dialog>
			</div>
		</div>
		<footers/>
	</div>
</template>

<script>
	import headertop from '../../components/headertop.vue';
	import headers from '../../components/header.vue';
	import footers from "../../components/footer.vue"
	var showStock,allowPlaceAnOrder,groupnos,salegroups
	
	
	export default{
		components:{footers,headertop,headers},
		data(){
			showStock = sessionStorage.getItem('showStock'); //
			allowPlaceAnOrder = sessionStorage.getItem('allowPlaceAnOrder');
			salegroups=sessionStorage.getItem('salegroups')
			if(salegroups&&salegroups!='undefined')groupnos=JSON.parse(sessionStorage.getItem('salegroups'))[0]
			return {
				allactive: false,			//全选
				pageno: 1,
				pagesize: 10,
				cartpublic: null,			//商品公共参数
				cartlistdata: [],			//全部商品
				yxgoods: [],			//有效商品
				wxgoods: [],			//无效商品
				checkAll: false,
				checkgoods: [],			//选中的商品
				isIndeterminate: false,			//全选按钮
				dialogTableVisible: false,
				dialogFormVisible: false,
				isfixed: false,			//是否固定结算
				isLoadsuccess: false ,//是否加载完成
				pricetype: {'1': '折扣' ,'2' :'立减价格' ,'3' :'秒杀',  '5':'买赠'},
				promotesalesList:[], //促销列表
				chageerpcustno:false, //选择促销列表
				promotesalesid:'', //当前选中的促销id
				itempromoteIn:'',//当前点击要更换促销的商品
				showStock:showStock,
				allowPlaceAnOrder:allowPlaceAnOrder
			}
		},
		created: function created() {
			var token = sessionStorage.getItem('token');
			if(!token)this.$router.push({path:'/login'})
			this.getTenantid(()=> {
				this.cartlist(); //详情
				this.getRichtext()
				console.log(5650,this.$store.state)
				this.$store.dispatch('PUBLIC',{type:'headeractive',res:0})
			})

		},
		methods: {
			// 加减
			reduce: function reduce(item, index) {
				var value = index ? parseInt(item.qty) + 1 : item.qty - 1;
				value < 1 ? value = 1 : value = value;
				this.cartlistdata.filter(function(list) {
					if (list.goodsid == item.goodsid) {
						list.qty = value;
					}
				});
			},
			
			// 输入购物车数量
			changeinput: function changeinput(item) {
				var qty = item.qty;
				if (qty == '') qty = 100;
				this.cartlistdata.filter(function(list) {
					if (list.goodsid == item.goodsid) {
						if (list.batchno == item.batchno) list.qty = qty;
					}
				});
				this.updateCart(item)
			},
			
			
			// 选择商品
			ckeckitemfn: function ckeckitemfn(item) {
				var isSelect = item.itemSelected ? 1 : 0;
				this.selectGoods(item.cartgoodsid, isSelect);
			},
			
			// 选择促销弹窗
			chageerpcustnobtn:function chageerpcustnobtn(item){
				this.chageerpcustno=true
				this.promotesalesid=''
				this.promotesalesList=item.promoteInfoVOList
				this.itempromoteIn=item
				console.log(item)
			},
			
			
			// 确定选择促销
			selectPromotesales:function selectPromotesales(key){
				this.chageerpcustno=false
				if(key==1)this.updateitempromote()
				
			},
			
			
			// 选择商品或取消选择 // POST /b2bshoppingcart/updateselectitem
			selectGoods: function selectGoods(cartgoodsid, isSelect) {

				var Url = '/b2bshoppingcart/updateselectitem';
				var data = {
					cartgoodsid: cartgoodsid,
					isSelect: isSelect,
				};
				this.requset(Url, data).then(()=>{this.cartlist();});
				
			},
			
			// 全选
			allcheck: function allcheck() {
				var itemResultList = this.cartlistdata;
				this.allactive = !this.allactive;
				var isSelect = this.allactive ? 0 : 1;
				var itemSelected = [];
				for (let k in itemResultList){
					for (var i in itemResultList[k].cartItemList) {
						if (!itemResultList[k].cartItemList[i].outStock) {
							if(itemResultList[k].cartItemList[i].isvisible!=0)itemSelected.push(itemResultList[k].cartItemList[i].cartgoodsid);
						}
					}
				}
				itemSelected = itemSelected.join(',');
				if(itemSelected)this.selectGoods(itemSelected, isSelect);
			},
			
			
			//读取购物车列表POST /b2bshoppingcart/getcart
			cartlist: function cartlist() {

				// checkUser();

				this.requset('/b2bshoppingcart/getcart', {}).then((data)=> {
					this.isLoadsuccess = true;
					if (data.status == 'success') {
						this.setcartlist(data)
					} else {
						this.authorization(data);
					}
				})
			},
			
			// 购物车列表设置
			setcartlist:function setcartlist(data){
				var wxgoods = [];
				var yxgoods = [];
				var totalSelQty=0 //当前选中商品的数量
				var itemResultList=data.data.itemResultList
				// debugger
				for (let k in itemResultList){
					
					itemResultList[k].cartItemList=itemResultList[k].cartItemList.filter((res,i)=>{
						
						if(res){
							if ( res.itemInvalid){
								wxgoods.push( res);
							}else{
								if ( res.outStock) {
									wxgoods.push( res);
								} else {
									yxgoods.push( res);
									if(res.itemSelected)totalSelQty++
									return res
								}
							}
						}
					})
					
				}
				
				itemResultList=itemResultList.filter(res=>{if(res.cartItemList.length) return res})
				console.log('itemResultList',itemResultList)

				console.log('wxgoods',wxgoods)
				this.wxgoods = wxgoods; 
				this.yxgoods = yxgoods;
				this.cartlistdata =itemResultList; //购物车列表
				data.data.orderTotalAmount = data.data.orderTotalAmount.toFixed(2); //总金额
				this.cartpublic = data.data; //公共参数
				
				console.log('totalQty-wxgoods',data.data.totalQty,wxgoods.length,totalSelQty)
				if ((data.data.totalQty-wxgoods.length) - totalSelQty == 0) {
					this.allactive = true;
				} else {
					this.allactive = false;
				}
				this.cartNumber(); //查询购物车数量
			},
			
			
			// 更换促销
			updateitempromote(){
				var Url= '/b2bshoppingcart/updateitempromote'
				var data={
					promotesalesid:this.promotesalesid,
					cartgoodsid:this.itempromoteIn.cartgoodsid,
				}
			
				this.requset(Url, data).then((data)=>{
					if (data.status == 'success')this.cartlist()
				});
				
		
			},
			
			
			// 删除购物车
			delcart(item) {
				var itemResultList = this.cartlistdata;
				var itemSelected = [];
				if (item == 'all') {
					for (let k in itemResultList){
						for (var i in itemResultList[k].cartItemList) {
							if (itemResultList[k].cartItemList[i].itemSelected) {
								itemSelected.push(itemResultList[k].cartItemList[i].cartgoodsid);
							}
						}
					}
					
					if (itemSelected.length == 0) {
						message('您还没有选中商品');
					} else {
						itemSelected = itemSelected.join(',');
						this.reducecarnow(itemSelected);
					}
				} else {
					this.reducecarnow(item.cartgoodsid);
				}
			},
		
		
			/*删除购物车列表*/
			reducecarnow: function reducecarnow(cartgoodsid) {
				var Url = '/b2bshoppingcart/batchremoveitem';
				
				var data = {cartgoodsids: cartgoodsid,salesalegroupno:groupnos?groupnos.groupno:''};
				this.requset(Url, data).then((data)=>{
					if (data.status == 'success') {
						this.$notify({title: '提示',message: '删除成功',type: 'success',duration:2000});
						this.cartlist();
						this.allactive = false;
						this.cartNumber(); //查询购物车数量
					} else {
						this.$notify({title: '提示',message: data.message,type: 'error',duration:2000});
					}
				});
				
			},
			
			
			
			// 列表点击提交
			dialogTableVisiblefn: function dialogTableVisiblefn() {
				this.dialogTableVisible = true;
				console.log(this.checkedCities);
			},
			
			
			
			// 点击结算
			account: function account() {
				var itemResultList = this.cartlistdata,
					cartpublic = this.cartpublic,
					allowPlaceAnOrder=this.allowPlaceAnOrder
				var selectedGoods = [];
				var self=this
				for (let k in itemResultList){
					for (var i in itemResultList[k].cartItemList) {
						if (itemResultList[k].cartItemList[i].itemSelected) {
							if(i!=0){delete itemResultList[k].cartItemList[i].gift}
							selectedGoods.push(itemResultList[k].cartItemList[i]);
							
						}
					}
				}
				console.log(selectedGoods,itemResultList);
				
					// debugger
				if (!selectedGoods.length) {
					this.$message({message: '请选择商品',type: 'warning'});
					return;
				} else {
					var count=0
					console.log('selectedGoods',selectedGoods)
					for(let res of selectedGoods){
						if(res.qty>res.totalstock){
							if(allowPlaceAnOrder==1){
								this.$confirm('您购买的商品中，部分商品库存不足，是否继续购买?', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning',
										customClass:this.color,
										callback:function(action){
											if(action=='confirm'){
												var checkedCities = selectedGoods;
												var toShoppingArr = {
													checkedCities: checkedCities,
													allcount: cartpublic.orderTotalAmount
												};
												sessionStorage.setItem('toShoppingArr', JSON.stringify(toShoppingArr));
												sessionStorage.setItem('addtrade', 0);
												sessionStorage.setItem('returngoods', 0);
												self.$router.push({path:'/submitted'})

											}else{
												
											}
										}
									})
							}else{
								self.$message.error('您购买的商品中部分库存不足，请修改购买数量');
								return
							}
						}else{
							console.log(count,selectedGoods.length-1)
							if(count==selectedGoods.length-1){	
								var checkedCities = selectedGoods;
								var toShoppingArr = {
									checkedCities: checkedCities,
									allcount: cartpublic.orderTotalAmount,
									cartpublic:cartpublic
								};
								sessionStorage.setItem('toShoppingArr', JSON.stringify(toShoppingArr));
								sessionStorage.setItem('addtrade', 0);
								sessionStorage.setItem('returngoods', 0);
								self.$router.push({path:'/submitted'})
							}
						}
						count++
					}
					
				}
			},
			
			
			// 修改购物数量POST /b2bshoppingcart/updatequantity
			updateCart: function updateCart(goods) {
				var _this4 = this;
				if (parseInt(goods['qty']) <= goods.littleqty) {
					goods['qty'] = goods.littleqty ? goods.littleqty * 1 : 1;
				} else {
					if (goods.littleqty) goods['qty'] = Math.ceil(parseInt(goods['qty']) / goods.littleqty) * goods.littleqty;
				}
				
				console.log(goods);
			
				var showStock=sessionStorage.getItem('showStock');
				var showststockn = "库存不足";
				if (showStock != 0) showststockn = '、目前库存为' + goods.totalstock;
				if (goods.qty > goods.totalstock) {
					if(allowPlaceAnOrder==1){
						if (goods.batchno == "defaultbno") {
							this.$confirm('您购买的【' + goods.goodsname + '】、批号【默认批号】' + showststockn + '，商品下次到货时间预计为【' + goods.arrivalday +
								'】天，是否继续?', '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning',
									customClass:this.color,
									callback:function(action){
										if(action=='confirm'){
											_this4.updatenumber(goods);
										}else{
											_this4.cartlist();
										}
									}
								})
						} else {
							_this4.cartlist();
							this.$message.error(showststockn + '，修改采购数量失败');
						}
					}else{
						_this4.cartlist();
						this.$message.error(showststockn + '，修改采购数量失败');
					}
					
				} else {
					this.updatenumber(goods);
				}
			},
			
			
			// 修改数量请求
			updatenumber: function updatenumber(goods) {
				var userid=sessionStorage.getItem('userid');
				var Url = '/b2bshoppingcart/updatequantity';
				var data = {
					qty: goods.qty,
					cartgoodsid: goods.cartgoodsid,
					userid: userid
				};
				this.requset(Url, data).then((data)=>{this.cartlist();});
				
			},
			
			
			
			
			// 查看订单立即采购
			succsee: function succsee(i) {
				if (i) {
					this.$router.push({path:'/goodslist'})
				} else {
					this.$router.push({path:'/member'})
				}
			},
			
			
			// 跳转详情
			gotodetail: function gotodetail(id) {
				this.$router.push({path:'/detail',query:{goodsid:id}})

			},
			
			
			// 获取滚动距离
			menu: function menu() {
				// offsetHeight
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop; // 获取元素高度
		
				var height = this.$refs.element.offsetHeight - 550; // 是否固定结算条
		
				if (this.scroll > height) {
					this.isfixed = false;
				} else {
					this.isfixed = true;
				}
			},
			scoill: function scoill(stype) {
				var height = this.$refs.yxgoodsscoll.offsetHeight;
				if (stype == 'all') {
					document.documentElement.scrollTop = 200;
				} else {
					document.documentElement.scrollTop = height + 200;
				}
			}
		},
	}
</script>

<style>
	.el-dialog__title{color: #FFFFFF;}
	.cart .el-input-number--mini{width: 130px;}
</style>
